.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.img-size{
  max-width: 15px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes jump {
	0% { top: 0px; } 50% { top: 5px; } 100% { top: 0px; }
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}


.home-page-container{
  /* font-family: 'Montserrat' ; */
  font-family: 'Roboto', sans-serif;

}

input{
  /* font-family: 'Montserrat' ; */
  font-family: 'Roboto', sans-serif;
}

textarea{
  /* font-family: 'Montserrat' ; */
  font-family: 'Roboto', sans-serif;
  resize: none;
}

aside{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px !important;
  width: 40px !important;
  /* background-color: rgb(162, 209, 96) !important; */
  background-color: #1b9ed9 !important;
  border-radius: 50%;
  outline:none;
  
 } 

svg{
  width: 80% !important;
  height: 90% !important;
}
.employee-details{
  margin: 0 auto;
  text-align: center;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1025px){

  .flex-column-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex-container{
    display: flex;
    padding:10px;
    /* background: rgba(120, 122, 120, 0.4); */
    background: rgba(0, 0, 0, 0.53);
    
  } 
  
  .overlay{
    position: absolute;
    width: 100%;
    height: 95%;
    top: 45px;
    left: 0px;
    background: rgba(0, 0, 0, 0.48);
    z-index: 0;
    opacity: 0.9;

  }

  .navbar{
    display: flex;
    justify-content: space-between;
    height: 45px;
    padding: 0 130px 0 130px;

    /* display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: #ffffff;
    width: 100%; */
  }

  .logo-image{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-list{
    display: flex;
    align-items: center;
  }

  .navbar-list-item{
    font-size: 14px;
    font-weight: 500;
    color: #1a1f24;
    margin-left: 40px;
    cursor: pointer;
  }

  .navbar-list-active-item{
    border-bottom: 4px solid #35a0d9;
    line-height: 50px;
  }

  .top-header{
    background: url('../src/assets/images/cover_page.jpg');
    background-size: cover ;
    background-position: center;
    background-repeat: no-repeat;
    height: 95vh;
    margin-bottom: 30px;
    
    
  }

  .section-heading{
    font-size: 50px;
    color: #1a1f23;
    font-weight: bold;
    margin-bottom: 80px;
    
  }

  .blur-sub-heading{
    width: 75%;
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: 500;
    color: #9aa8b6;
    text-align: center;
    line-height: 1.55;
  }

  .top-header-heading{
    line-height: 1.3;
    color: #ffffff;
    width: 45%;
    text-align: center;
    text-shadow: 1px 1px 20px #000000;
  }

  .top-header-subheading{
    font-size: 18px;
    font-weight: normal;
    line-height: 1.38;
    color:#fff;
    width: 65%;
    text-align:center;
    animation-delay: 0.5s;
    font-weight: bold;
  }

  .learn-more-section{
    animation-delay: 0.8s;
    position: relative;
    top: 75px;
  }

  .learn-more{
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin: 67px 642px 12px;
  }

  .learn-more-icon{
    position: relative;
    /* top: 85px; */
    animation: 1s ease-in 0s infinite normal none running jump;
    cursor: pointer;
    /* margin-top: 9px; */
  }

  .initiatives-section{
    padding: 0 130px 0 130px;
    margin-bottom: 100px;
    margin-top: 100px;
  }

  .initiatives-tabs-section{
    display: flex;
    flex-direction: column;
    width: 62%;
    margin-bottom: 40px;
    position: relative;
  }

  .initiatives-tabs{
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    /* width: 100%; */
    border-bottom: solid 3px #e7eaee;
    line-height: 3.5;
  }

  .initiatives-tab{
    color: #1a1f23;
    font-size: 1.3vw;
    font-weight: bold;
    cursor: pointer;
  }

  .blue-underline{
    height: 3px;
    background-color: #1b9ed9;
    position: absolute;
    bottom: 0;
    width: 23%;
    transition: transform .3s cubic-bezier(.645,.045,.355,1);
  }

  .health-underline{
    transform: translateX(0%);
  }

  .sanitation-underline{
    transform: translateX(174%);
  }

  .water-underline{
    transform: translateX(335%);
  }

  .initiatives-content{
    width: 85%;
    display: flex;
    justify-content: center;
  }

  .initiatives-image{
    width: 44%;
    margin-right: 25px;
    border-radius: 6px;
  }

  .initiatives-img{
    width: 100%;
  }

  .initiatives-texts{
    width: 47%;
  }

  .initiatives-content-heading{
    color: #1a1f23;
    font-size: 34px;
    font-weight: bold;
    line-height: 1.56;
    margin-bottom: 15px;
  }

  .initiatives-content-subheading{
    display: flex;
    margin-bottom: 30px;
    align-items: center;
  }

  .initiatives-text{
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
    color: #1a1f23;
  }

  .initiatives-contact-us{
    color: #8fc641;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
  }

  .contact-us-text{
    margin-right: 10px;
    transition: all 0.3s ;
    cursor: pointer;
  }

  .contact-us-text:hover{
    margin-right: 15px;
  }


  .contact-us-arrow{
    width: 16px;
  }

  .about-us-section{
    margin: 100px 0;
    background-color: #f6f6f6;
    /* padding: 100px 0; */
    height: 450px;
  }

  .about-us-subheading{
    font-size: 22px;
    font-weight: 500;
    line-height: 1.55;
    color: #9aa8b6;
    width: 59%;
    text-align: center;
  }

  .our-team-section{
    margin: 100px 0;
    padding: 0 130px 0 130px;
  }

  .our-team-subheading{
    width: 71%;
  }

  .employees-list{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .employee-image{
    width: 340px;
    /* height: 340px; */
  }

  .employee-name{
    color: #1a1f23;
    font-size: 18px;
    font-weight: bold;
    margin: 6px 0;
  }

  .employee-position{
    font-size: 18px;
    color: #9aa8b6;
    margin: 6px 0;
  }

  .employee-email{
    font-size: 16px;
    color: #9aa8b6;
    margin: 6px 0;
  }

  .contact-us-section{
    margin: 100px 0 12px 0;
    padding: 70px 0;
    background-color: #f6f6f6;
  }

  .contact-us-field{
    border-radius: 4px;
    background-color: #ffffff;
    color: #1a1f23;
    line-height: 2.83;
    font-size: 18px;
    border: none;
    outline: none;
    margin: 10px 0;
    padding: 0 15px;
  }

  .contact-us-input{
    width: 540px;
    height: 60px;
    
  }

  .contact-us-textarea{
    width: 540px;
    height: 200px;
    line-height: 1.5;
    padding-top: 10px;
  }

  .contactus-form-btn{
    width: 540px;
    padding: 15px 15px;
    text-align: center;
    color: #ffffff;
    background-color: #8fc641;
    border-radius: 4px;
    font-weight: 600;
    margin-top: 12px;
    cursor: pointer;
  }

  ::placeholder{
    font-size: 16px;
    color: #61788e;
  }

  .footer{
    background-color: #f7f7f7;
  }

  .footer-top-section{
    display: flex;
    justify-content: space-between;
    padding: 60px 130px 90px 130px;
  }

  .footer-top-left-section{
    width: 40%;
  }

  .footer-top-right-section{
    width: 450px;
  }

  .footer-heading{
    color: #1a1f23;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .footer-subheading-1{
    color: #000000;
    font-size: 20px;
    text-decoration: none;
  }

  .footer-subheading-2{
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .contact-details{
    display: flex;
    justify-content: space-between;
    flex-direction: column;

  }

  .contacts{
    display: flex;
    flex-direction: column;
  }

  .footer-bottom-section{
    height: 70px;
    background-color: #e7e7e7;
    padding: 0 130px 0 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .error-box{
    font-size: 14px;
    margin-top: 5px;
    color: #f27f7f;
    
  }

  i{
    margin-left: 4px;
    font-size: 18px;
    cursor: pointer;
  }

  .success-msg{
    color: green;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    animation: FadeAnimation 3s ease-in .5s forwards;
  }



  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: scroll;
  }

  .success-modal {
    width: 545px;
    height: 170px;
    background-color: #ffffff;
    padding: 32px;
    position: relative;
    border-radius: 6px;
    color: #1a1f23;
  }

  .modal-text{
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .modal-sub-text{
    margin-bottom: 35px;
    font-size: 18px;
    font-weight: 500;
  }

  .modal-btn{
    border-radius: 4px;
    background-color: #8fc641;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    padding: 12px 160px;
    cursor: pointer;
  }
  .footer-icon{
    margin-left: 10px;
  }
}  

   






@media only screen and (min-width: 569px) and (max-width: 1024px){

  .flex-column-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex-container{
    display: flex;
    padding:10px;
    /* background: rgba(120, 122, 120, 0.4); */
    background: rgba(0, 0, 0, 0.53);
    
  }  
  .overlay{
    position: absolute;
    width: 100%;
    height: 95%;
    top: 45px;
    left: 0px;
    background: rgba(0, 0, 0, 0.48);
    z-index: 0;
    opacity: 0.9;

  }


  .navbar{
    display: flex;
    justify-content: space-between;
    height: 45px;
    padding: 0 37px 0 37px;

    /* display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: #ffffff;
    width: 100%; */
  }

  .logo-image{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-list{
    display: flex;
    align-items: center;
  }

  .navbar-list-item{
    font-size: 14px;
    font-weight: 500;
    color: #1a1f24;
    margin-left: 40px;
    cursor: pointer;
  }

  .navbar-list-active-item{
    border-bottom: 4px solid #35a0d9;
    line-height: 50px;
  }

  .top-header{
    background: url('../src/assets/images/cover_page.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 95vh;
    margin-bottom: 25px;
    
  }

  .section-heading{
    font-size: 36px;
    color: #1a1f23;
    font-weight: bold;
    margin-bottom: 60px;
  }

  .blur-sub-heading{
    width: 68%;
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: 500;
    color: #9aa8b6;
    text-align: center;
    line-height: 1.55;
  }

  .top-header-heading{
    line-height: 1.3;
    color: #ffffff;
    width: 75%;
    text-align: center;
    font-size: 50px;
  }

  .top-header-subheading{
    font-size: 18px;
    font-weight: normal;
    line-height: 1.38;
    color:#fff;
    width: 65%;
    text-align: center;
    animation-delay: 0.5s;
    font-weight: bold;
    text-shadow: 1px 1px 20px #000000;
  }

  .learn-more-section{
    animation-delay: 0.8s;
    position: relative;
    top: 75px;
  }

  .learn-more{
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }

  .learn-more-icon{
    position: relative;
    /* top: 85px; */
    animation: 1s ease-in 0s infinite normal none running jump;
    cursor: pointer;
    margin-top: 10px;
  }

  .initiatives-section{
    /* padding: 0 36px 0 36px; */
    margin-bottom: 100px;
    margin-top: 100px;
  }

  .initiatives-tabs-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
    position: relative;
  }

  .initiatives-tabs{
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    /* width: 100%; */
    border-bottom: solid 3px #e7eaee;
    line-height: 3.5;
  }

  .initiatives-tab{
    color: #1a1f23;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }

  .blue-underline{
    height: 3px;
    background-color: #1b9ed9;
    position: absolute;
    bottom: 0;
    width: 23%;
    transition: transform .3s cubic-bezier(.645,.045,.355,1);
  }

  .health-underline{
    transform: translateX(0%);
  }

  .sanitation-underline{
    transform: translateX(174%);
  }

  .water-underline{
    transform: translateX(335%);
  }

  .initiatives-content{
    width: 95%;
    display: flex;
    justify-content: center;
  }

  .initiatives-image{
    width: 366px;
    margin-right: 25px;
    border-radius: 6px;
  }

  .initiatives-img{
    width: 100%;
  }

  .initiatives-texts{
    width: 47%;
  }

  .initiatives-content-heading{
    color: #1a1f23;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.56;
    margin-bottom: 25px;
  }

  .initiatives-content-subheading{
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    width: 85%;
  }

  .initiatives-text{
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
    color: #1a1f23;
  }

  .initiatives-contact-us{
    color: #8fc641;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
  }

  .contact-us-text{
    margin-right: 10px;
    transition: all 0.3s ;
    cursor: pointer;
  }

  .contact-us-text:hover{
    margin-right: 15px;
  }


  .contact-us-arrow{
    width: 16px;
  }

  .about-us-section{
    margin: 100px 0;
    background-color: #f6f6f6;
    /* padding: 100px 0; */
    height: 500px;
  }

  .about-us-subheading{
    font-size: 22px;
    font-weight: 500;
    line-height: 1.55;
    color: #9aa8b6;
    width: 71%;
    text-align: center;
  }

  .our-team-section{
    margin: 100px 0;
    padding: 0 16px 0 16px;
  }

  .our-team-subheading{
    width: 85%;
  }

  .employees-list{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .employee-image{
    width: 98%;
  }

  .employee-name{
    color: #1a1f23;
    font-size: 18px;
    font-weight: bold;
    margin: 6px 0;
  }

  .employee-position{
    font-size: 14px;
    color: #9aa8b6;
    margin: 6px 0;
  }

  .employee-email{
    font-size: 14px;
    color: #9aa8b6;
    margin: 6px 0;
  }

  .contact-us-section{
    margin: 100px 0 40px 0;
    padding: 70px 0;
    background-color: #f6f6f6;
  }

  .contact-us-field{
    border-radius: 4px;
    background-color: #ffffff;
    color: #1a1f23;
    line-height: 2.83;
    font-size: 18px;
    border: none;
    outline: none;
    margin: 10px 0;
    padding: 0 20px;
  }

  .contact-us-input{
    width: 620px;
    height: 60px;
    
  }

  .contact-us-textarea{
    width: 620px;
    height: 200px;
    line-height: 1.5;
    padding-top: 10px;
  }

  .contactus-form-btn{
    width: 620px;
    padding: 15px 15px;
    text-align: center;
    color: #ffffff;
    background-color: #8fc641;
    border-radius: 4px;
    font-weight: 600;
    margin-top: 12px;
    cursor: pointer;
  }

  ::placeholder{
    font-size: 16px;
    color: #61788e;
  }

  .footer{
    background-color: #f7f7f7;
  }

  .footer-top-section{
    display: flex;
    flex-direction: column;
    padding: 44px 37px 90px 37px;
  }

  .footer-top-left-section{
    width: 40%;
  }

  .footer-top-right-section{
    width: 450px;
    margin-top: 50px;
  }

  .footer-heading{
    color: #1a1f23;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .footer-subheading-1{
    color: #000000;
    font-size: 20px;
    text-decoration: none;
  }

  .footer-subheading-2{
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .contact-details{
    display: flex;
    justify-content: space-between;
    flex-direction: column;

  }

  .contacts{
    display: flex;
    flex-direction: column;
  }

  .footer-bottom-section{
    height: 95px;
    background-color: #e7e7e7;
    padding: 0 36px 0 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }

  .error-box{
    font-size: 14px;
    margin-top: 5px;
    color: #f27f7f;
    
  }

  i{
    margin-left: 4px;
    font-size: 18px;
    cursor: pointer;
  }

  .success-msg{
    color: green;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    animation: FadeAnimation 3s ease-in .5s forwards;
  }



  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: scroll;
  }

  .success-modal {
    width: 570px;
    background-color: #ffffff;
    padding: 25px 32px;
    position: relative;
    border-radius: 6px;
    color: #1a1f23;
  }

  .modal-text{
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .modal-sub-text{
    margin-bottom: 35px;
    width: 75%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }

  .modal-btn{
    border-radius: 4px;
    background-color: #8fc641;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    padding: 20px 130px;
    cursor: pointer;
  }
  .footer-icon{
    margin-left: 10px;
  }
}  
  




















@media only screen and (max-width: 568px){
  .flex-column-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex-container{
    display: flex;
    padding:10px;
    /* background: rgba(120, 122, 120, 0.4); */
    background: rgba(0, 0, 0, 0.53);
  
  }  


  .navbar{
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 0 12px 0 12px;

    /* display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: #ffffff;
    width: 100%; */

    /* padding: 0 12px 0 12px;
    position: fixed;
    width: 100%; */
  }

  .overlay{
    position: absolute;
    width: 100%;
    height: 95vh;
    top: 60px;
    left: 0px;
    background: rgba(0, 0, 0, 0.48);
    z-index: 0;
    opacity: 0.9;

  }
  .logo-image{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-button{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-list{
    display: flex;
    align-items: center;
  }

  .navbar-list-item{
    font-size: 14px;
    font-weight: 500;
    color: #1a1f24;
    margin-left: 40px;
    cursor: pointer;
  }

  .navbar-list-active-item{
    border-bottom: 4px solid #35a0d9;
    line-height: 50px;
  }

  .main-content{
    width: 100%;
  }
  .top-header{
    background: url('../src/assets/images/cover_page.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 95vh;
    margin-bottom: 35px;
    
  }

  .section-heading{
    font-size: 24px;
    color: #1a1f23;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .blur-sub-heading{
    width: 92%;
    margin-bottom: 15px;
    font-size: 14px;
    color: #9aa8b6;
    text-align: center;
    line-height: 1.57;
  }

  .top-header-heading{
    line-height: 1.3;
    color: #ffffff;
    width: 95%;
    text-align: center;
    font-size: 26px;
    margin-top: 30px;
  }

  .top-header-subheading{
    font-size: 16px;
    font-weight: normal;
    line-height: 1.38;
    color:#fff;
    width: 85%;
    text-align: center;
    animation-delay: 0.5s;
    margin-top: 15px;
    font-weight: bold;
    text-shadow: 1px 1px 20px #000000;

  }

  .learn-more-section{
    animation-delay: 0.8s;
    position: relative;
    top: 45px;
  }

  .learn-more{
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }

  .learn-more-icon{
    position: relative;
    /* top: 85px; */
    animation: 1s ease-in 0s infinite normal none running jump;
    cursor: pointer;
    margin-top: 10px;
  }

  .initiatives-section{
    /* padding: 0 36px 0 36px; */
    margin-bottom: 100px;
    margin-top: 100px;

  }

  .initiatives-tabs-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 22px;
    position: relative;
  }

  .initiatives-tabs{
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    /* width: 100%; */
    border-bottom: solid 3px #e7eaee;
    line-height: 3;
  }

  .initiatives-tab{
    color: #1a1f23;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  .blue-underline{
    height: 4px;
    background-color: #1b9ed9;
    position: absolute;
    bottom: 0;
    width: 30%;
    transition: transform .3s cubic-bezier(.645,.045,.355,1);
  }

  .health-underline{
    transform: translateX(0%);
  }

  .sanitation-underline{
    transform: translateX(108%);
    width: 33%;
  }

  .water-underline{
    transform: translateX(256%);
    width: 28%;
  }

  .initiatives-content{
    width: 93%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .initiatives-image{
    border-radius: 6px;
  }

  .initiatives-img{
    width: 100%;
  }

  .initiatives-texts{
    /* width: 47%; */
  }

  .initiatives-content-heading{
    color: #1a1f23;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.56;
    margin-bottom: 20px;
    margin-top: 8px;
  }

  .initiatives-content-subheading{
    display: flex;
    margin-bottom: 25px;
    align-items: flex-start;
    /* width: 85%; */
  }

  .initiatives-text{
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
    color: #1a1f23;
  }

  .initiatives-contact-us{
    color: #8fc641;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
  }

  .contact-us-text{
    margin-right: 15px;
    transition: all 0.3s ;
    cursor: pointer;
  }

  .contact-us-text:hover{
    margin-right: 15px;
  }


  .contact-us-arrow{
    width: 20px;
  }

  .about-us-section{
    margin: 100px 0 70px 0;
    background-color: #f6f6f6;
    padding: 55px 0;
    /* height: 336px; */
  }

  .about-us-subheading{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.55;
    color: #9aa8b6;
    /* width: 71%; */
    text-align: center;
  }

  .our-team-section{
    margin-bottom: 100px;
    /* padding: 0 16px 0 16px; */
  }

  .our-team-subheading{
    /* width: 85%; */
    margin: 15px 0;
  }

  .employees-list{
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }

  .employee-details{
    margin: 0 auto;
    text-align: center;
    padding-bottom: 10px;
  }

  .employee-image{
    /* width: 98%; */
  }

  .employee-name{
    color: #1a1f23;
    font-size: 14px;
    font-weight: bold;
    margin: 6px 0;
  }

  .employee-position{
    font-size: 14px;
    color: #9aa8b6;
    margin: 6px 0;
  }

  .employee-email{
    font-size: 14px;
    color: #9aa8b6;
    margin: 6px 0;
  }

  .contact-us-section{
    margin: 100px 0 15px 0;
    padding: 70px 12px 20px 12px;
    background-color: #f6f6f6;
  }

  .contact-us-form{
    width: 100%;
  }

  .contact-us-field{
    border-radius: 4px;
    background-color: #ffffff;
    color: #1a1f23;
    line-height: 2.83;
    font-size: 18px;
    border: none;
    outline: none;
    margin: 5px 0;
    padding: 0 20px;
  }

  .contact-us-input{
    /* width: 620px; */
    width: 85%;
    height: 60px;
    
  }

  .contact-us-textarea{
    /* width: 620px; */
    width: 85%;
    height: 240px;
    line-height: 1.5;
    padding-top: 10px;
  }

  .contactus-form-btn{
    /* width: 620px; */
    width: 85%;
    padding: 22px 15px;
    text-align: center;
    color: #ffffff;
    background-color: #8fc641;
    border-radius: 4px;
    font-weight: 600;
    margin-top: 12px;
    cursor: pointer;
  }

  ::placeholder{
    font-size: 16px;
    color: #61788e;
  }

  .footer{
    background-color: #f7f7f7;
  }

  .footer-top-section{
    display: flex;
    flex-direction: column;
    padding: 38px 12px 10px 12px;
  }

  .footer-top-left-section{
    /* width: 40%; */
  }

  .footer-top-right-section{
    /* width: 390px; */
    margin-top: 80px;
  }

  .footer-heading{
    color: #1a1f23;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .footer-subheading-1{
    color: #000000;
    font-size: 20px;
    text-decoration: none;
  }

  .footer-subheading-2{
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    /* margin-bottom: 6px; */
  }

  .contact-details{
    display: flex;
    flex-direction: column;

  }

  .contacts{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .footer-bottom-section{
    /* height: 95px; */
    background-color: #e7e7e7;
    padding: 30px 36px 30px 36px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
  
  .social-media-icons{
    margin-bottom: 20px;  
  }

  .error-box{
    font-size: 14px;
    margin-top: 5px;
    color: #f27f7f;
    
  }

  i{
    margin-left: 4px;
    font-size: 18px;
    cursor: pointer;
  }

  .success-msg{
    color: green;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    animation: FadeAnimation 3s ease-in .5s forwards;
  }



  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: scroll;
  }

  .success-modal {
    width: 570px;
    background-color: #ffffff;
    padding: 25px 32px;
    position: relative;
    border-radius: 6px;
    color: #1a1f23;
  }

  .modal-text{
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .modal-sub-text{
    margin-bottom: 60px;
    width: 75%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }

  .modal-btn{
    border-radius: 4px;
    background-color: #8fc641;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    padding: 20px 130px;
    cursor: pointer;
  }
  .footer-icon{
    margin-left: 10px;
  }

  .menu-bar {
    position: fixed;
    top: 60px;
    left: 0;
    display: flex;
    padding-top: 50px;
    padding-left: 35px;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
    z-index: 1000;
    background-color: #ffffff;
    /* background-color: rgba(0, 0, 0, 0.3); */
    overflow: scroll;
  }

  .menubar-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .menubar-list-item{
    font-size: 22px;
    font-weight: 500;
    color: #1a1f24;
    margin-bottom: 25px;
  }

  .menubar-list-active-item{
    border-bottom: 3px solid #35a0d9;
  }

}  
